import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { NgxEchartsModule } from 'ngx-echarts';
import { Socket, SocketIoModule } from 'ngx-socket-io';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { AuthService } from './auth/auth.service';

@NgModule({
  declarations: [],
  imports: [SocketIoModule, BrowserModule, HammerModule, AppRoutingModule, AuthModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: Socket,
      useFactory: (authService: AuthService) => {
        return new Socket({
          url: environment.backend.ws,
          options: {
            extraHeaders: { Authorization: authService.getAccessToken() || '' },
          },
        });
      },
      deps: [AuthService],
    },
  ],
  bootstrap: [],
})
export class AppModule {}
