import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject, filter, firstValueFrom, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

export interface Profile {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  profilePicture: string;
  emailVerified: boolean;
  bodyWeight: UserBodyWeight;
  roles: UserRole[];
  personalRecordsCount: number;
}

export interface UserRole {
  name: string;
}

export interface UserBodyWeight {
  weight: number;
  when: Date;
}

function tokenIsExpired(token: string): boolean {
  const { exp } = jwtDecode<{ exp: number }>(token);
  const now = new Date().getTime();
  if (now > exp * 1000) {
    return true;
  }

  return false;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  readonly _user: BehaviorSubject<Profile | null> = new BehaviorSubject<Profile | null>(null);
  readonly $user: Observable<Profile | null> = this._user.asObservable();
  private _isAuthenticated: boolean = false;

  constructor(private readonly http: HttpClient) {
    this.isAuthenticated()
      .then((authenticated: boolean) => {
        this._isAuthenticated = authenticated;
        if (authenticated && this._user.value === null) {
          firstValueFrom(this.http.get<Profile>(`${environment.backend.host}/profile`)).then((profile: Profile) => this._user.next(profile));
        }
      })
      .catch(console.error);
  }

  async login(username: string, password: string): Promise<Profile | null> {
    try {
      const { access_token, refresh_token } = await firstValueFrom(
        this.http.post<{ access_token: string; refresh_token: string }>(`${environment.backend.host}/auth/login`, {
          username,
          password,
        }),
      );

      localStorage.setItem('access_token', access_token);
      localStorage.setItem('refresh_token', refresh_token);

      const profile: Profile = await firstValueFrom(this.http.get<Profile>(`${environment.backend.host}/profile`));
      this._user.next(profile);
      return profile;
    } catch (err) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      console.error(err);
      throw err;
    }
  }

  async refreshLogin(): Promise<Profile | null> {
    try {
      const token = localStorage.getItem('refresh_token');
      if (!token) {
        this._user.next(null);
        return null;
      }

      const { access_token, refresh_token } = await firstValueFrom(
        this.http.post<{ access_token: string; refresh_token: string }>(`${environment.backend.host}/auth/refresh-token`, {
          refresh_token: token,
        }),
      );

      localStorage.setItem('access_token', access_token);
      localStorage.setItem('refresh_token', refresh_token);

      const profile: Profile = await firstValueFrom(this.http.get<Profile>(`${environment.backend.host}/profile`));
      this._user.next(profile);
      return profile;
    } catch (err) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      console.error(err);
      throw err;
    }
  }

  async signup(username: string, password: string): Promise<void> {
    try {
      const { access_token, refresh_token } = await firstValueFrom(
        this.http.post<{ access_token: string; refresh_token: string }>(`${environment.backend.host}/auth`, {
          username,
          password,
        }),
      );

      localStorage.setItem('access_token', access_token);
      localStorage.setItem('refresh_token', refresh_token);

      const profile: Profile = await firstValueFrom(this.http.get<Profile>(`${environment.backend.host}/profile`));
      this._user.next(profile);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async updatePassword(oldPassword: string, newPassword: string): Promise<void> {
    try {
      await firstValueFrom(this.http.put<void>(`${environment.backend.host}/profile/password`, { oldPassword, newPassword }));
      this.logout();
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  logout(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    this._user.next(null);
  }

  currentUser(): Profile | null {
    return this._user.value;
  }

  async isAuthenticated(): Promise<boolean> {
    const accessToken: string | null = localStorage.getItem('access_token');
    const isAuthenticated: boolean = !!accessToken;

    if (isAuthenticated && tokenIsExpired(accessToken as string)) {
      const refresh_token: string | null = localStorage.getItem('refresh_token');
      if (refresh_token && !tokenIsExpired(refresh_token)) {
        const profile = await this.refreshLogin();
        if (profile) {
          return true;
        }
      }

      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      return false;
    }

    return isAuthenticated;
  }

  hasRole(role: string): boolean {
    if (!this._isAuthenticated) {
      return false;
    }

    const user = this._user.value;
    if (user?.roles?.length && user.roles.map((r) => r.name).includes(role)) {
      return true;
    }

    return false;
  }

  async hasRoleAsync(role: string): Promise<boolean> {
    if (!(await this.isAuthenticated())) {
      return false;
    }

    const user = await firstValueFrom(this.$user.pipe(filter((u) => !!u)));
    if (user?.roles?.length && user.roles.map((r) => r.name).includes(role)) {
      return true;
    }

    return false;
  }

  getAccessToken(): string {
    return localStorage.getItem('access_token') ?? '';
  }

  async resetPassword(username: string): Promise<void> {
    await firstValueFrom(this.http.delete<void>(`${environment.backend.host}/auth/${username}/password`));
  }
}
