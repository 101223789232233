/// <reference types="@angular/localize" />

import { enableProdMode, importProvidersFrom } from '@angular/core';
import * as Sentry from '@sentry/angular';

import { bootstrapApplication } from '@angular/platform-browser';
import 'hammerjs';
import { AppComponent } from './app/app.component';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: 'https://b74e07bf7ced447989daa7a4b6c7d89d@o499011.ingest.sentry.io/5577077',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  environment: environment.production ? 'production' : 'local',
  release: `track-my-wod@${environment.appVersion}`,
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/app.trackmywod\.com/],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});

bootstrapApplication(AppComponent, {
  providers: [importProvidersFrom(AppModule)],
});
