import packageInfo from '../../package.json';

export const environment = {
  appVersion: packageInfo.version,
  production: true,
  backend: {
    host: 'https://api.trackmywod.com',
    ws: 'https://api.trackmywod.com',
  },
  appConfig: {
    enableFriendsList: false,
  },
};
